<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<BatchExport v-if="dialog.refresh2" :row="{ name: 1 }" @refresh="refreshDialog" />
		<el-card>
			<el-row style="margin-bottom: 15px;">
				<el-col :span="7">
					<span class="text-primary text-pad-right">日期：</span>
					<el-date-picker style="width: 320px;" v-model="dateTime" type="daterange" class="input searchInput"
					 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable :unlink-panels="true" value-format="yyyy-MM-dd HH:mm:ss"
					 :default-time="['00:00:00','23:59:59']" size="mini" @change="changeCondition('3')" />
				</el-col>
				<el-col v-if="$admin" :span="4">
					<span class="text-primary text-pad-right">公司：</span>
					<el-select v-model="companyId" size="mini" class="input searchInput" filterable clearable @change="changeCondition('2')">
						<el-option v-for="(v, i) in company" :key="i" :value="v.id" :label="v.companyName" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<span class="text-primary text-pad-right">部门：</span>
					<el-cascader ref="dept" v-model="departId" :options="depart" class="searchInput" size="mini" :props="{
					        expandTrigger: 'hover',
					        value: 'id',
					        children: 'sons',
					        label: 'departName',
					        emitPath: false,
					        checkStrictly: true,
					    }"
					 filterable clearable @change="changeCondition('0')" />
				</el-col>
				<el-col :span="5">
					<span class="text-primary text-pad-right">岗位：</span>
					<el-select v-model="positionId" size="mini" class="input searchInput" clearable @change="changeCondition('0')">
						<el-option v-for="(v, i) in position" :key="i" :value="v.id" :label="v.positionName" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<el-input style="width: 250px;" v-model="searchName" class="input searchInput" size="mini" suffix-icon="el-icon-search"
					 placeholder="搜索" @input="changeCondition('0')" />
				</el-col>
			</el-row>
		</el-card>
		<el-card>
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="space-between">
							<el-col>
								<el-button v-if="
                                            $hasBtn(
                                                'sys:permissions:org:archives:add'
                                            )
                                        "
								 type="primary" size="mini" @click="
                                        openDialog({
                                            title: '添加',
                                            addOr: 'add',
                                        })
                                    ">添加</el-button>
								<el-button v-if="
                                            $hasBtn(
                                                'sys:permissions:org:archives:batch:import'
                                            )
                                        "
								 type="warning" size="mini" @click="openExport">批量导入</el-button>
								<el-button v-if="
                                            $hasBtn(
                                                'sys:permissions:org:archives:batch:delete'
                                            )
                                        "
								 type="danger" size="mini" @click="removeAll">批量删除</el-button>
							</el-col>
							<el-col :span="3">
								<vxe-button v-if="
                                            $hasBtn(
                                                'sys:permissions:org:archives:import'
                                            )
                                        "
								 @click="exportList">导出</vxe-button>
								<vxe-button @click="getListNow">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="500" row-id="company_id" :sort-config="{ remote: true }"
				 :filter-config="{ remote: true }" :checkbox-config="{ reserve: true }" :data="table.list" :loading="loading"
				 @checkbox-all="handleSelectionChange" @checkbox-change="handleSelectionChange">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="realName" title="姓名" />
					<vxe-table-column field="phone" title="手机号码" />
					<vxe-table-column field="departName" title="部门" show-overflow="title" />
					<vxe-table-column field="positionName" title="岗位" show-overflow="title" />
					<vxe-table-column field="companyName" title="所属公司" show-overflow="title" />
					<vxe-table-column field="attendanceTime" title="考勤月份" />
					<vxe-table-column field="attendanceDays" title="出勤天数" />
					<vxe-table-column field="slickLeave" title="病假/天" />
					<vxe-table-column field="privateAffairLeave" title="事假/天" />
					<vxe-table-column field="maternityLeave" title="产假/天" />
					<vxe-table-column title="操作">
						<template v-slot="{ row }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="
                                            $hasBtn(
                                                'sys:permissions:org:archives:delete'
                                            )
                                        "
									 icon="el-icon-delete" @click.native="
                                            remove({ del: 'single' }, row)
                                        ">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		bytesToFile
	} from "@/utils/handleByte";
	import {
		dropUserPosition,
		depart,
		company,
	} from '@/api'
	import {
		rowStyle,
		headerStyle
	} from "@/utils/tableStyleJs";
	import Operate from "./Operate";
	import BatchExport from "./BatchExport";
	export default {
		name: "Index",
		components: {
			Operate,
			BatchExport,
		},
		data() {
			return {
				loading: false,
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: "",
					sortBy: "",
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					refresh2: false, // 每次刷新DOM
					refresh3: false, // 每次刷新DOM
					refreshRole: false, // 每次刷新DOM
					show: false,
					show2: false,
					show3: false,
					showRole: false,
				},
				dateTime: "",
				searchName: "",
				startTime: "",
				endTime: "",
				serviceQualityConfigIds: "",
				serviceQualityType: "",
				departId: "",
				companyId: this.$admin ? '' : this.$companyId,
				positionId: "",
				serviceQualityConfig: [],
				serviceType: [],
				company: [],
				position: [],
				depart: [],
				userIds: []
			};
		},
		created() {
			this.getParentCode('SERVICE_QUALITY_TYPE');
			if (!this.$admin) {
				this.getDepart();
				this.getPosition();
				this.getList();
			} else {
				this.getCompany();
			}
		},
		mounted() {},
		methods: {
			rowStyle,
			headerStyle,
			getListNow() {
				this.userIds = [];
				this.getList();
			},
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true;
				this.dialog.show = true;
				this.dialog.info = info;
				this.dialog.row = row;
			},
			// 打开批量导入
			openExport() {
				this.dialog.refresh2 = true;
				this.dialog.show2 = true;
			},
			getCompany() {
				this.$axiosReq(company, null, {
					pageSize: 10000
				}, 'get').then(res => {
					this.company = res.data.content;
					this.companyId = res.data.content[0].id;
					this.getList();
				})
			},
			getPosition() {
				this.$axiosReq(
					dropUserPosition,
					null, {
						companyId: this.companyId,
					},
					"get"
				).then((res) => {
					this.position = res.data;
				});
			},
			getDepart() {
				this.$axiosReq(
					depart,
					null, {
						companyId: this.companyId,
						type: 1,
						pageSize: 10000,
					},
					"get"
				).then((res) => {
					this.depart = res.data;
				});
			},
			changeCondition(num) {
				this.table.currentPage = 1
				if (num == 1) {
					this.serviceQualityConfig = [];
					this.serviceQualityConfigIds = '';
					if (this.serviceQualityType) {
						this.getViolateConfig();
					}
				} else if (num == 2) {
					this.position = [];
					this.depart = [];
					this.departId = "";
					this.positionId = "";
					if (this.companyId) {
						this.getDepart();
						this.getPosition();
					}
				} else if (num == 3) {
					this.startTime = '';
					this.endTime = '';
					if (this.dateTime) {
						this.startTime = this.dateTime[0];
						this.endTime = this.dateTime[1];
					}
				}
				this.getList();
			},
			getViolateConfig() {
				this.$axiosReq('/archives/server/escort/web/serviceQualityConfig', null, {
					pageSize: 1000,
					serviceQualityType: this.serviceQualityType,
				}, 'get').then(res => {
					this.serviceQualityConfig = res.data.content
				})
			},
			getList() {
				this.loading = true;
				this.$axiosReq(
						'/archives/server/escort/web/attendance',
						null, {
							currentPage: this.table.currentPage,
							pageSize: this.table.pageSize,
							serviceQualityType: this.serviceQualityType,
							serviceQualityConfigIds: this.serviceQualityConfigIds,
							startTime: this.startTime,
							search: this.searchName,
							endTime: this.endTime,
							companyId: this.companyId,
							departId: this.departId,
							positionId: this.positionId,
						},
						"get"
					)
					.then((res) => {
						this.table.list = res.data.content;
						this.table.total = Number(res.data.totalElements);
					})
					.finally((res) => {
						this.loading = false;
					});
			},
			remove(info, row) {
				this.$confirm("确认删除吗！！！", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$axiosReq(
							'/archives/server/escort/web/attendance/' + row.id,
							null,
							null,
							"delete"
						).then((res) => {
							this.$message.success(res.msg);
							this.getList();
						});
					})
					.catch(() => {
						this.$message.info("删除失败！！！");
					});
			},
			removeAll() {
				var that_ = this;
				if (that_.userIds.length < 1) {
					this.$message.error("请勾选需要删除的选项");
					return false;
				}
				this.$confirm("确认批量删除吗！！！", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$axiosReq(
							'/archives/server/escort/web/attendance',
							that_.userIds,
							null,
							"delete"
						).then((res) => {
							//console.log(JSON.stringify(res))
							that_.$message.success("批量删除成功");
							that_.userIds = [];
							that_.getList();
						});
					})
					.catch(() => {
						that_.$message.info("取消了批量删除！！！");
					});
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage;
				this.getList();
			},
			refreshDialog() {
				this.dialog.refresh = false;
				this.dialog.refresh2 = false;
				this.dialog.refresh3 = false;
				this.dialog.refreshRole = false;
				this.getList();
			},
			handleSelectionChange(val) {
				const data = val.records;
				this.userIds = [];
				for (let i = 0; i < data.length; i++) {
					this.userIds.push(data[i].id);
				}
			},
			getParentCode(id) {
				this.$axiosReq('/data/server/escort/web/dict/parentCode/' + id, null, null, 'get').then(res => {
					this.serviceType = res.data
				})
			},
			exportList() {
				this.loading = true;
				this.$axiosReq('/archives/server/escort/web/attendance/export', null, {
						currentPage: this.table.currentPage,
						pageSize: this.table.pageSize,
						startTime: this.startTime,
						search: this.searchName,
						endTime: this.endTime,
						companyId: this.companyId,
						departId: this.departId,
						positionId: this.positionId,
					}, "get")
					.then((res) => {
						bytesToFile(res.data.bytes, res.data.fileName);
					})
					.finally((e) => {
						this.loading = false;
					});
			},
		},
	};
</script>

<style scoped></style>
