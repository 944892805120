var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-container"},[(_vm.dialog.refresh)?_c('Operate',{attrs:{"dialog":_vm.dialog.show,"row":_vm.dialog.row,"info":_vm.dialog.info},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"get-list":_vm.getList,"refresh":_vm.refreshDialog}}):_vm._e(),(_vm.dialog.refresh2)?_c('BatchExport',{attrs:{"row":{ name: 1 }},on:{"refresh":_vm.refreshDialog}}):_vm._e(),_c('el-card',[_c('el-row',{staticStyle:{"margin-bottom":"15px"}},[_c('el-col',{attrs:{"span":7}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("日期：")]),_c('el-date-picker',{staticClass:"input searchInput",staticStyle:{"width":"320px"},attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","clearable":"","unlink-panels":true,"value-format":"yyyy-MM-dd HH:mm:ss","default-time":['00:00:00','23:59:59'],"size":"mini"},on:{"change":function($event){return _vm.changeCondition('3')}},model:{value:(_vm.dateTime),callback:function ($$v) {_vm.dateTime=$$v},expression:"dateTime"}})],1),(_vm.$admin)?_c('el-col',{attrs:{"span":4}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("公司：")]),_c('el-select',{staticClass:"input searchInput",attrs:{"size":"mini","filterable":"","clearable":""},on:{"change":function($event){return _vm.changeCondition('2')}},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}},_vm._l((_vm.company),function(v,i){return _c('el-option',{key:i,attrs:{"value":v.id,"label":v.companyName}})}),1)],1):_vm._e(),_c('el-col',{attrs:{"span":4}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("部门：")]),_c('el-cascader',{ref:"dept",staticClass:"searchInput",attrs:{"options":_vm.depart,"size":"mini","props":{
					        expandTrigger: 'hover',
					        value: 'id',
					        children: 'sons',
					        label: 'departName',
					        emitPath: false,
					        checkStrictly: true,
					    },"filterable":"","clearable":""},on:{"change":function($event){return _vm.changeCondition('0')}},model:{value:(_vm.departId),callback:function ($$v) {_vm.departId=$$v},expression:"departId"}})],1),_c('el-col',{attrs:{"span":5}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("岗位：")]),_c('el-select',{staticClass:"input searchInput",attrs:{"size":"mini","clearable":""},on:{"change":function($event){return _vm.changeCondition('0')}},model:{value:(_vm.positionId),callback:function ($$v) {_vm.positionId=$$v},expression:"positionId"}},_vm._l((_vm.position),function(v,i){return _c('el-option',{key:i,attrs:{"value":v.id,"label":v.positionName}})}),1)],1),_c('el-col',{attrs:{"span":4}},[_c('el-input',{staticClass:"input searchInput",staticStyle:{"width":"250px"},attrs:{"size":"mini","suffix-icon":"el-icon-search","placeholder":"搜索"},on:{"input":function($event){return _vm.changeCondition('0')}},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1)],1)],1),_c('el-card',[_c('el-row',[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',[(
                                            _vm.$hasBtn(
                                                'sys:permissions:org:archives:add'
                                            )
                                        )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.openDialog({
                                            title: '添加',
                                            addOr: 'add',
                                        })}}},[_vm._v("添加")]):_vm._e(),(
                                            _vm.$hasBtn(
                                                'sys:permissions:org:archives:batch:import'
                                            )
                                        )?_c('el-button',{attrs:{"type":"warning","size":"mini"},on:{"click":_vm.openExport}},[_vm._v("批量导入")]):_vm._e(),(
                                            _vm.$hasBtn(
                                                'sys:permissions:org:archives:batch:delete'
                                            )
                                        )?_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":_vm.removeAll}},[_vm._v("批量删除")]):_vm._e()],1),_c('el-col',{attrs:{"span":3}},[(
                                            _vm.$hasBtn(
                                                'sys:permissions:org:archives:import'
                                            )
                                        )?_c('vxe-button',{on:{"click":_vm.exportList}},[_vm._v("导出")]):_vm._e(),_c('vxe-button',{on:{"click":_vm.getListNow}},[_vm._v("刷新")])],1)],1)]},proxy:true}])}),_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":"500","row-id":"company_id","sort-config":{ remote: true },"filter-config":{ remote: true },"checkbox-config":{ reserve: true },"data":_vm.table.list,"loading":_vm.loading},on:{"checkbox-all":_vm.handleSelectionChange,"checkbox-change":_vm.handleSelectionChange}},[_c('vxe-table-column',{attrs:{"type":"checkbox","width":"60","fixed":"left"}}),_c('vxe-table-column',{attrs:{"type":"seq","width":"60"}}),_c('vxe-table-column',{attrs:{"field":"realName","title":"姓名"}}),_c('vxe-table-column',{attrs:{"field":"phone","title":"手机号码"}}),_c('vxe-table-column',{attrs:{"field":"departName","title":"部门","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"positionName","title":"岗位","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"companyName","title":"所属公司","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"attendanceTime","title":"考勤月份"}}),_c('vxe-table-column',{attrs:{"field":"attendanceDays","title":"出勤天数"}}),_c('vxe-table-column',{attrs:{"field":"slickLeave","title":"病假/天"}}),_c('vxe-table-column',{attrs:{"field":"privateAffairLeave","title":"事假/天"}}),_c('vxe-table-column',{attrs:{"field":"maternityLeave","title":"产假/天"}}),_c('vxe-table-column',{attrs:{"title":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_c('el-dropdown',{attrs:{"size":"small","type":"primary"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" 操作"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                                            _vm.$hasBtn(
                                                'sys:permissions:org:archives:delete'
                                            )
                                        )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){return _vm.remove({ del: 'single' }, row)}}},[_vm._v("删除")]):_vm._e()],1)],1)]}}])})],1),_c('div',{staticStyle:{"height":"20px"}}),_c('el-pagination',{attrs:{"background":"","current-page":_vm.table.currentPage,"page-sizes":[5, 10, 15, 20, 50, 100],"page-size":_vm.table.pageSize,"layout":"total, ->, sizes, prev, pager, next, jumper","total":_vm.table.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }