<template>
    <div
        class="exportPerson flex-row-box flex-justify-center flex-cont-center"
        @click="closeBox"
    >
        <div class="exportPersonBox">
            <div class="flex-row-box headerList">
                <div class="exportTitle" />
                <div class="flex-1 text-center">批量导入</div>
                <div class="text-center exportTitle" @click="closeOver">
                    <i class="el-icon-error" />
                </div>
            </div>
            <div class="contentBox">
                <el-form label-width="110px">
                    <el-form-item label="模板" :required="true">
                        <el-button type="primary" @click="exportTableE"
                            >下载模板</el-button
                        >
                    </el-form-item>
                    <el-form-item label="上传文件" :required="true">
                        <el-button type="primary" @click="inputFlieClick"
                            >点击上传</el-button
                        >
                        <input
                            ref="inputer"
                            type="file"
                            id="fileId"
                            class="inputFlieNone"
                            @change="getFile"
                        />
                        <div v-if="inputFlieText" class="inputText">
                            {{ inputFlieText }}
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex-row-box flex-justify-center">
                <el-button
                    type="primary"
                    style="width: 130px"
                    @click="postAddPerson"
                    >提交</el-button
                >
                <div style="width: 100px" />
                <el-button type="info" style="width: 130px" @click="closeOver"
                    >取消</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { company, dropUserPosition, downloadTemplate, uesrBatch } from "@/api";
import { bytesToFile } from "@/utils/handleByte";
export default {
    name: "BatchExport",
    props: {
        row: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            show: true,
            fileList: [],
            company: [],
            companyId: "",
            inputFlieText: "",
            inputFlieUrl: "",
			isSystemData: [],
			isSystemDataId: '0'
        };
    },
    created() {},
    methods: {
        closeBox(e) {
            if (
                e.target.className ===
                "exportPerson flex-row-box flex-justify-center flex-cont-center"
            ) {
                this.show = false;
                this.$emit("refresh");
            }
        },
        closeOver() {
            this.show = false;
            this.$emit("refresh");
        },
        exportTableE() {
            this.loading = true;
            this.$axiosReq('/archives/server/escort/web/rewardPunishment/downloadTemplate', null, null, "get")
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        inputFlieClick() {
            var input = this.$refs.inputer;
            input.click();
        },
        getFile() {
            var that_ = this;
            const inputFile = that_.$refs.inputer.files[0];
            that_.inputFlieText = inputFile.name;
        },
        postAddPerson() {
            var that_ = this;
            var inputFile = that_.$refs.inputer.files[0];
            if (!inputFile) {
                that_.inputFlieText = "";
                that_.$message.error("请上传正确的文件");
                return false;
            }
            that_.inputFlieUrl = new FormData();
            that_.inputFlieUrl.append("file", inputFile);
            this.$axiosReq(
                '/archives/server/escort/web/rewardPunishment/batchImport',
                that_.inputFlieUrl,
                null,
                "post"
            ).then((res) => {
                that_.$message.success(res.msg);
                that_.closeOver();
            });
			setTimeout(function () {
			    var nowFile = that_.$refs.inputer;
			    nowFile.value = "";
			    nowFile.outerHTML = nowFile.outerHTML;
			    that_.inputFlieText = "";
			}, 1000);
        },
    },
};
</script>

<style scoped>
.exportPerson {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
}

.exportPersonBox {
    width: 40%;
    height: 70%;
    background-color: #ffffff;
}

.headerList {
    padding: 20px 0;
    border-bottom: 1px solid #c3c3c3;
    color: #5c8bff;
    font-weight: bold;
    font-size: 20px;
}

.exportTitle {
    width: 50px;
    cursor: pointer;
}

.contentBox {
    padding: 20px;
    box-sizing: border-box;
    font-weight: bold;
}

.inputFlie {
    width: 100px;
    height: 40px;
    font-size: 14px;
    color: #ffffff;
    background-color: #5a8cff;
    border-radius: 5px;
    font-weight: 400;
}

.inputFlieNone {
    width: 150px;
    height: 40px;
    display: none;
}

.inputText {
    font-size: 16px;
}
</style>
