<template>
	<el-dialog :visible.sync="show" width="40%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row :gutter="0">
			<el-col :span="24">
				<el-form ref="ruleForm" label-width="150px" class="allForm">
					<el-form-item label="姓名" :required="true" class="formInput">
						<el-input v-model="realName" placeholder="请选择姓名" class="formInput" @keyup.enter.native="getUserList" @focus="userListShowHide = true"
						 @blur="userListHide" />
						<div v-if="userListShowHide" class="userListShow">
							<div class="userOne" v-for="(v,i) in userList" :key="i" @click="userOneClick(v)">{{v.realName}} ({{v.phone}})</div>
							<div v-if="userList.length < 1" style="padding-left: 20px;">点击回车搜索</div>
							<div v-if="userList.length < 1" style="padding-left: 20px;">无数据</div>
						</div>
					</el-form-item>
					<el-form-item label="手机号码" :required="true" class="formInput">
						<el-input v-model="form.phone" class="formInput" readonly="readonly" />
					</el-form-item>
					<el-form-item label="所属公司" class="formInput">
						<el-input v-model="form.companyName" class="formInput" readonly="readonly" />
					</el-form-item>
					<el-form-item label="考勤月份" :required="true" class="formInput">
						<el-date-picker v-model="form.attendanceTime" type="month" value-format="yyyy-MM" placeholder="选择日期" @change="changeTime">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="出勤天数" :required="true" class="formInput">
						<el-input v-model="form.attendanceDays" type="number" class="formInput" />
					</el-form-item>
					<el-form-item label="病假" class="formInput">
						<el-input v-model="form.slickLeave" type="number" class="formInput" />
					</el-form-item>
					<el-form-item label="事假" class="formInput">
						<el-input v-model="form.privateAffairLeave" type="number" class="formInput" />
					</el-form-item>
					<el-form-item label="产假" class="formInput">
						<el-input v-model="form.maternityLeave" type="number" class="formInput" />
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<div slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button type="primary" @click="commit">提交</el-button>
			<el-button type="info" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		company,
	} from '@/api'

	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				companyId: this.$admin ? '' : this.$companyId,
				form: {
					companyId: '',
					companyName: '',
					userId: '',
					attendanceTime: '',
					attendanceDays: '',
					maternityLeave: '',
					phone: '',
					privateAffairLeave: '',
					slickLeave: '',
				},
				realName: '',
				company: [],
				userList: [],
				userListShowHide: false,
			}
		},
		created() {
			this.getUserList();
		},
		methods: {
			changeTime(v) {
			  console.log(v)
			},
			getUserList() {
				this.$axiosReq('/oauth/server/escort/web/user', null, {
					pageSize: 100,
					companyId: this.companyId,
					searchName: this.realName
				}, 'get').then(res => {
					this.userList = res.data.content
				})
			},
			userOneClick(obj) {
				this.realName = obj.realName;
				this.form.userId = obj.id;
				this.form.phone = obj.phone;
				this.form.companyId = obj.companyId;
				this.form.companyName = obj.companyName;
				this.form.departId = obj.departId;
				this.form.departName = obj.departName;
				this.form.positionId = obj.positionId;
				this.form.positionName = obj.positionName;
				// this.getUserList();
			},
			userListHide() {
				let that_ = this;
				if (!this.realName) {
					this.form.userId = '';
					this.phone = '';
					this.form.companyId = '';
					this.form.companyName = '';
					this.form.departId = '';
					this.form.departName = '';
					this.form.positionId = '';
					this.form.positionName = '';
				}
				setTimeout(function() {
					that_.userListShowHide = false;
				}, 300)
			},
			// 提交添加
			commit() {
				if (!this.realName) {
					this.$message.error('请选择已有用户姓名')
					return
				} else if (!this.form.attendanceTime) {
					this.$message.error('请选择考勤月份！')
					return
				} else if (!this.form.attendanceDays) {
					this.$message.error('请选择出勤天数！')
					return
				}
				this.$axiosReq('/archives/server/escort/web/attendance', this.form, null, 'post').then(res => {
					this.$message.success(res.msg)
					this.close()
					this.$emit('get-list')
				})
			},
			changeCompany(v) {
				console.log(v)
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			}
		}
	}
</script>

<style scoped>
	.userListShow {
		width: 100%;
		height: 210px;
		background-color: #FFFFFF;
		border: 1px solid #EEEEEE;
		border-radius: 5px;
		position: absolute;
		left: 0;
		top: 42px;
		z-index: 11;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.userListShow::-webkit-scrollbar {
		display: none;
	}

	.userOne {
		width: 100%;
		height: 33px;
		line-height: 33px;
		padding-left: 10px;
		box-sizing: border-box;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}

	.userOne:hover {
		background-color: #CCCCCC;
	}
</style>
