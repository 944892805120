<template>
	<el-dialog :visible.sync="show" width="40%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row :gutter="0">
			<el-col :span="24">
				<el-form ref="ruleForm" label-width="150px" class="allForm">
					<el-form-item label="姓名" :required="true" class="formInput">
						<el-input v-model="realName" placeholder="请选择姓名" class="formInput" @keyup.enter.native="getUserList" @focus="userListShowHide = true"
						 @blur="userListHide" />
						<div v-if="userListShowHide" class="userListShow">
							<div class="userOne" v-for="(v,i) in userList" :key="i" @click="userOneClick(v)">{{v.realName}} ({{v.phone}})</div>
							<div v-if="userList.length < 1" style="padding-left: 20px;">点击回车搜索</div>
							<div v-if="userList.length < 1" style="padding-left: 20px;">无数据</div>
						</div>
					</el-form-item>
					<el-form-item label="手机号码" :required="true" class="formInput">
						<el-input v-model="phone" class="formInput" readonly="readonly" />
					</el-form-item>
					<el-form-item label="部门" class="formInput">
						<el-input v-model="form.departName" class="formInput" readonly="readonly" />
					</el-form-item>
					<el-form-item label="岗位" class="formInput">
						<el-input v-model="form.positionName" class="formInput" readonly="readonly" />
					</el-form-item>
					<el-form-item label="所属公司" class="formInput">
						<el-input v-model="form.companyName" class="formInput" readonly="readonly" />
					</el-form-item>
					<el-form-item label="所在线路" class="formInput">
						<el-input v-model="form.lineName" class="formInput" readonly="readonly" />
					</el-form-item>
					<el-form-item label="服务时间" :required="true">
						<el-date-picker v-model="form.serviceTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期" @change="changeTime">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="服务类型" :required="true" class="formInput">
						<el-select v-model="form.serviceQualityType" class="w-100" @change="violateConfigChange('2')">
							<el-option v-for="(v,i) in serviceType" :key="i" :value="v.dictCode" :label="v.dictName" />
						</el-select>
					</el-form-item>
					<el-form-item label="服务事件" :required="true" class="formInput">
						<el-input v-model="form.serviceQualityEvent" placeholder="请选择服务事件" class="formInput" @input="violateConfigChange('1')"
						 @keyup.enter.native="getViolateConfig" @focus="violateConfigShowHide = true" @blur="violateConfigHide" />
						<div v-if="violateConfigShowHide" class="userListShow">
							<div class="userOne" v-for="(v,i) in violateConfig" :key="i" @click="violateConfigClick(v)">{{v.serviceQualityEvent}}</div>
							<div v-if="violateConfig.length < 1" style="padding-left: 20px;">点击回车搜索</div>
							<div v-if="violateConfig.length < 1" style="padding-left: 20px;">无数据</div>
						</div>
					</el-form-item>
					<el-form-item label="处罚结果" :required="true" class="formInput">
						<el-input v-if="!form.serviceQualityConfigId" v-model="form.serviceQualityAward" class="formInput" placeholder="请输入服务奖罚" />
						<el-input v-if="form.serviceQualityConfigId" v-model="form.serviceQualityAward" class="formInput" readonly="readonly" placeholder="请选择违反事件" />
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<div slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button type="primary" @click="commit">提交</el-button>
			<el-button type="info" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		company,
	} from '@/api'

	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				companyId: this.$admin ? '' : this.$companyId,
				form: {
					companyId: '',
					companyName: '',
					departId: '',
					departName: '',
					positionId: '',
					positionName: '',
					serviceQualityAward: '',
					serviceQualityEvent: '',
					serviceQualityType: '',
					userId: '',
					serviceQualityConfigId: '',
					serviceTime: '',
					lineName: '',
					lineId: '',
				},
				phone: '',
				realName: '',
				company: [],
				serviceType: [],
				violateConfig: [],
				userList: [],
				userListShowHide: false,
				violateConfigShowHide: false,
			}
		},
		created() {
			this.getUserList();
			this.getParentCode('SERVICE_QUALITY_TYPE');
		},
		methods: {
			changeTime(v) {
			  console.log(v)
			},
			violateConfigChange(v) {
				this.form.serviceQualityConfigId = '';
				this.form.serviceQualityAward = '';
				if (v == 2) {
					this.getViolateConfig();
				}
			},
			getViolateConfig() {
				this.$axiosReq('/archives/server/escort/web/serviceQualityConfig', null, {
					pageSize: 100,
					serviceQualityType: this.form.serviceQualityType,
					serviceQualityEvent: this.form.serviceQualityEvent,
				}, 'get').then(res => {
					this.violateConfig = res.data.content
				})
			},
			violateConfigClick(obj) {
				this.form.serviceQualityEvent = obj.serviceQualityEvent;
				this.form.serviceQualityAward = obj.serviceQualityAward;
				this.form.serviceQualityConfigId = obj.id;
				// this.getViolateConfig();
			},
			violateConfigHide(obj) {
				let that_ = this;
				setTimeout(function() {
					that_.violateConfigShowHide = false;
				}, 300)
			},
			getUserList() {
				this.$axiosReq('/oauth/server/escort/web/user', null, {
					pageSize: 100,
					companyId: this.companyId,
					searchName: this.realName
				}, 'get').then(res => {
					this.userList = res.data.content
				})
			},
			userOneClick(obj) {
				this.realName = obj.realName;
				this.form.userId = obj.id;
				this.phone = obj.phone;
				this.form.companyId = obj.companyId;
				this.form.companyName = obj.companyName;
				this.form.departId = obj.departId;
				this.form.departName = obj.departName;
				this.form.positionId = obj.positionId;
				this.form.positionName = obj.positionName;
				this.form.lineName = obj.lineName;
				this.form.lineId = obj.lineId;
				// this.getUserList();
			},
			userListHide() {
				let that_ = this;
				if (!this.realName) {
					this.form.userId = '';
					this.phone = '';
					this.form.companyId = '';
					this.form.companyName = '';
					this.form.departId = '';
					this.form.departName = '';
					this.form.positionId = '';
					this.form.positionName = '';
				}
				setTimeout(function() {
					that_.userListShowHide = false;
				}, 300)
			},
			getParentCode(id) {
				this.$axiosReq('/data/server/escort/web/dict/parentCode/' + id, null, null, 'get').then(res => {
					this.serviceType = res.data
				})
			},
			// 提交添加
			commit() {
				if (!this.realName) {
					this.$message.error('请选择已有用户姓名')
					return
				} else if (!this.form.serviceTime) {
					this.$message.error('请选择服务时间！')
					return
				} else if (!this.form.serviceQualityType) {
					this.$message.error('请选择服务类型！')
					return
				} else if (!this.form.serviceQualityEvent) {
					this.$message.error('请选择服务事件！')
					return
				} else if (!this.form.serviceQualityAward) {
					this.$message.error('请输入服务奖罚！')
					return
				}
				this.$axiosReq('/archives/server/escort/web/serviceQuality', this.form, null, 'post').then(res => {
					this.$message.success(res.msg)
					this.close()
					this.$emit('get-list')
				})
			},
			changeCompany(v) {
				console.log(v)
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			}
		}
	}
</script>

<style scoped>
	.userListShow {
		width: 100%;
		height: 210px;
		background-color: #FFFFFF;
		border: 1px solid #EEEEEE;
		border-radius: 5px;
		position: absolute;
		left: 0;
		top: 42px;
		z-index: 11;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.userListShow::-webkit-scrollbar {
		display: none;
	}

	.userOne {
		width: 100%;
		height: 33px;
		line-height: 33px;
		padding-left: 10px;
		box-sizing: border-box;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
	}

	.userOne:hover {
		background-color: #CCCCCC;
	}
</style>
